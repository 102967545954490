/*global*/
html{
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}
a,a:hover ,a:focus{color:#181927;}
a, a:hover, a:focus{text-decoration:none;}
ul, ol{margin:0; padding:0; list-style:none;}
h1, h2, h3, h4, h5, h6{margin:0;}
h1, h2, h3{font-weight:400; font-family: 'Teko', monospace; text-rendering: optimizeLegibility;}
h4,h5,h6{font-weight:400;}
input, button, textarea, select{font-family: 'Titillium Web', monospace;}
p{margin:0;}
*::-webkit-scrollbar{width:4px;background:0 0}
*::-webkit-scrollbar-track{background:0 0}
*::-webkit-scrollbar-thumb{background:rgba(0,0,0,.1);border-radius:8px;transition:background .3s}
*::-webkit-scrollbar-thumb:hover{background:rgba(0,0,0,.2)}
/*theme*/
body{
   font-family: 'Titillium Web', sans-serif;
   font-size:14px; 
   line-height:1.4;
   min-height:100%;
   font-weight:400;
   color:#181927;
   overflow-x:hidden;
   background-color:#f7f7f7;
}
.no-margin{
	margin:0 !important;
}
.no-padding{
 	padding:0 !important;
}
.flex-row{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.flex-row  > div{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-row  > div > div{
	width:100%;
}
.centered-img{
	display:block;
	max-width:100%;
	margin-left:auto;
	margin-right:auto;
}
/*buttons*/
.btn{
    padding: 11px 20px 9px;
    font-size: 14px;
	font-family:'Teko';
    font-weight:600;
	text-transform:uppercase;
	letter-spacing:3px;
    line-height:20px;
	min-height:40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: none;
    border-radius:12px;
	outline:none;
	transition:0.4s all;
	-webkit-transition:0.4s all; 
	-moz-transition:0.4s all;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: 0;
}
.btn-blue{
	background-color:#4DCEED;
	color:#181927;
}
.btn-blue:hover,
.btn-blue:focus{
	background-color:#181927;
	color:#fff;
}
.btn-border{
	border:2px solid rgba(18,21,25,.1);
	color:#181927;
}
.btn-border:hover,
.btn-border:focus{
	border-color:#4DCEED;
}
/*form*/
.form-list{
	position:relative;
}
ul.form-list li{
	margin-bottom:15px;
}
ul.form-list li:last-child{
	margin-bottom:0;
}
ul.form-list li label{
	font-size:13px;
	display:block;
	font-weight:700;
	letter-spacing:1px;
	text-transform:uppercase;
	color:#8B9198;
}
ul.form-list li label span{
	color:#4DCEED;
}
.input-box{
	position:relative;
}
.form-control{
    height: auto;
    padding: 0 15px;
    font-size: 15px;
    line-height: 50px;
    color: #181927;
    background-color: transparent;
    border: 1px solid #181927;
    border-radius:6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s linear all;
    -o-transition: 0.4s linear all;
    transition:0.4s linear all;
}
textarea.form-control{
	line-height:22px;
	min-height:150px;
	padding:15px;
}
.form-control:focus{
	border-color:#4DCEED;
	outline:none;
	-webkit-box-shadow: none;
    box-shadow: none;
}
.btn.full{
	width:100%;
	text-align:center;
}
/*left bar*/
.menu-toggle {
    display: none;
    line-height: 30px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
}
.menu-toggle svg {
    position: absolute;
    height: 30px;
    width: auto;
    fill: #181927;
    left: 0;
    top: 0;
}
.menu-toggle b{
	font-size: 13px;
    font-family: "Teko",sans-serif;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight:600;
}
.left-bar{
	padding:20px 25px;
	position:fixed;
	z-index:9;
	background-color:#fff;
	width:230px;
	top:0;
	left:0;
	height:100%;
	box-shadow:0px 0px 20px -15px rgba(0,0,0,0.25);
}
.banner-bg{
    width: 100vh;
    position: absolute;
    height: 100%;
    transform: rotate(90deg);
    left: 0;
	top:0;
	z-index:0;
}
.waves{
    position: absolute;
    width: 100%;
    height: 50px;
    display: block;
    bottom: 230px;
    left: 0;
}
.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}
.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
.staking-logo{
	margin-bottom:20px;
}
.staking-logo img,
.staking-logo svg{
	height:30px;
	width:auto;
}
.staking-menu{
	position:relative;
	margin-bottom:30px;
	overflow-y:auto;
	max-height:320px;
}
.staking-menu > li{
	position:relative;
	margin-bottom:8px;
}
.staking-menu > li:last-child{
	margin-bottom:0;
}
.staking-menu > li > a{
	display:block;
	background-color:#f7f7f7;
	border-radius:6px;
	line-height:24px;
	position:relative;
	padding:10px 10px 6px;
	padding-left:40px;
	font-size:15px;
	text-transform:uppercase;
	font-family:'Teko';
	letter-spacing:3px;
	transition:0.4s all;
	-webkit-transition:0.4s all; 
	-moz-transition:0.4s all;
}
.staking-menu > li.parent > a:after{ 
	content: "\f0dd";
	font-weight:900;
    font-family: "Font Awesome 5 Free";
	position:absolute;
	right:4px;
	top:50%;
	transform:translateY(-60%);
	-webkit-transform:translateY(-60%); 
	-moz-transform:translateY(-60%);
}
.staking-menu > li > a:hover{
	background-color:#4DCEED;
	color:#fff;
}
	.staking-menu > li > a > span,
	.staking-sub-menu > li > a > span {
		width: 20px;
		height: 20px;
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		text-align: center;
		line-height: 1;
	}

		.staking-menu > li > a > span svg,
		.staking-sub-menu > li > a > span svg {
			width: 100%;
			height: auto;
			transition: 0.4s all;
			-webkit-transition: 0.4s all;
			-moz-transition: 0.4s all;
		}

.staking-menu > li > a:hover span svg{
	fill:#fff;
}
.staking-sub-menu{
	padding-top:10px;
	display:none;
	padding-left:15px;
}
.staking-sub-menu > li{
	margin-bottom:5px;
}
.staking-sub-menu > li > a{
	display: block;
	background-color:#f7f7f7;
    line-height: 24px;
    position: relative;
    font-size: 15px;
	padding:7px 10px 4px;
	padding-left:40px;
    text-transform: uppercase;
    font-family: 'Teko';
	border-radius: 6px;
    letter-spacing: 3px;
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
}
.staking-sub-menu > li > a.eth-s{
	background:#ecf0f1;
}
.staking-sub-menu > li > a.bnb-s{
	background:#f3ba2f;
}
.staking-sub-menu > li > a.eth-s:hover{
	background-color:#3a3a3d;
	color:#fff;
}
.staking-sub-menu > li > a.bnb-s:hover{
	background-color:#181927;
	color:#f3ba2f;
}
.staking-sub-menu > li > a i{
	font-size:20px;
	position:absolute;
	top:50%;
	left:10px;
	transform:translateY(-50%);
	-webkit-transform:translateY(-50%); 
	-moz-transform:translateY(-50%); 
}
.staking-login{
	position:relative;
	z-index:1;
}
.staking-login a{
	display:block;
	padding:20px;
	text-align:center;
	font-family:'Teko';
	letter-spacing:2px;
	text-transform:uppercase;
	background-color:#f7f7f7;
	border-radius:12px;
	transition:0.3s all;
	-webkit-transition:0.3s all; 
	-moz-transition:0.3s all;
}
.staking-login a:hover{
	box-shadow:0px 0px 20px -15px rgba(0,0,0,0.25);
}
.staking-login a span{
	display:block;
	margin-bottom:5px;
}
.staking-login a span img{
	max-width:40px;
	margin:0 auto;
	display:block;
}
.social_header{
	position:absolute;
	bottom:15px;
	left:25px;
}
.social_header h3 {
    font-size: 13px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 30px;
    position: relative;
    padding-left: 40px;
}
.social_header h3:after {
    display: block;
    content: "";
    width: 30px;
    position: absolute;
    left: 0;
    top: 12px;
    height: 2px;
    background-color: #4DCEED;
}
.social_header ul li{
	float:left;
	margin-left:15px;
}
.social_header ul li:first-child{
	margin-left:0;
}
.social_header ul li a{
	display:block;
	line-height:30px;
	font-size:16px;
}
.theme-mode{
	margin-top:20px;
	background-color:#f7f7f7;
	border-radius:12px;
	position:relative;
	overflow:hidden;
}
.theme-mode input{
	display:none;
}
.t-b{
	width:50%;
	float:left;
	text-align:center;
}
.t-b label{
	display:block;
	margin:0;
	cursor:pointer;
	padding:10px;
	transition:0.3s all ease;
	-webkit-transition:0.3s all ease; 
	-moz-transition:0.3s all ease;
}
.t-b svg{
	height:20px;
	width:auto;
	display:block;
	margin:0 auto;
}
.t-b > input:checked + label{
	background:#4DCEED;
}
/*main*/
main{
	position:relative;
	z-index:9;
	padding:30px 30px 30px 0;
	margin-left:300px;
}
.main-title{
	margin-bottom:30px;
	position:relative;
}
.main-title h1{
	font-size:36px;
	letter-spacing:3px;
	text-transform:uppercase;
}
.main-title.clearfix h1{
	float:left;
}
.main-title > p{
	margin-top:5px;
	font-size:18px;
}
.main-title.clearfix > p{
	margin-top:0;
	float:left;
	margin-left:20px;
	padding-left:20px;
	margin-top:5px;
	border-left:1px solid #dedede;
}
.search-box{
	margin-top: 35px;
}
.search-box .input-box{
	position:relative;
}
.search-box .form-control{
	line-height:40px;
}
.search-box .input-box i{
	position:absolute;
	right:15px;
	top:50%;
	transform:translateY(-50%);
	-webkit-transform:translateY(-50%); 
	-moz-transform:translateY(-50%);
	color:#4DCEED;
}
.cancelled-orders{
	float:right;
	padding:8.5px 0;
}
.cancelled-orders h3 {
    float: left;
    margin-right: 5px;
    font-size: 16px;
    line-height: 25px;
	text-transform:uppercase;
	letter-spacing:2px;
}
.hide_cancelled{
	display:block;
	float:right;
	margin:0;
	width:50px;
	border-radius:30px;
	height:25px;
	line-height:25px;
	background-color:#181927;
	position:relative;
}
.hide_cancelled b{
	display:block;
	width:18px;
	height:18px;
	background-color:#fff;
	position:absolute;
	border-radius:30px;
	left:3px;
	top:3px;
}
.cancelled-orders input[type="checkbox"]:checked + label{
	background-color:#4DCEED;
}
.cancelled-orders input[type="checkbox"]:checked + label b{
	left:auto;
	right:4px;
}
 .cancelled-orders input[type="checkbox"]:checked + label:before{
	content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 11px;
    color: #000;
    top: 8px;
    line-height: 1;
    font-size: 10px;
}
.pool-filters{
	position:relative;
	background-color:#fff;
	box-shadow: 0px 0px 20px -15px rgba(0,0,0,0.25);
	border-radius:30px;
}
.pool-filters li{
	float:left;
	width:33.33333333%;
	text-align:center;
}
.pool-filters li input{
	display:none;
}
.pool-filters li label{
	display:block;
	padding:0 15px;
	line-height:42px;
	text-align:center;
	text-transform:uppercase;
	font-size:13px;
	font-weight:600;
	border-radius:30px;
	cursor:pointer;
	margin:0;
	transition:0.4s all;
	-webkit-transition:0.4s all; 
	-moz-transition:0.4s all;
}
.pool-filters li input:checked + label,
.pool-filters li label:hover{
    background-color:#4DCEED;
}
/*staking pools*/
main .theme-bg{
	position: absolute; /* Safari */
	top:50px;
	z-index:-1;
	font-size:600px;
	left:0;
	width:100%;
	text-align:center;
	line-height:1;
	color:#8A92B2;
	opacity:0.14;
}
main .theme-bg.bnb{
	opacity:0.08;
}
.staking-pool{
	margin:15px;
	position:relative;
	border-radius:12px;
	background: -moz-linear-gradient(-45deg, rgba(138,146,178,0.15) 0%, rgba(255,255,255,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(138,146,178,0.15) 0%,rgba(255,255,255,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(138,146,178,0.15) 0%,rgba(255,255,255,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#268a92b2', endColorstr='#ccffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	box-shadow: 0px 0px 20px -12px #8A92B2;
}
.bsc .staking-pool{
    box-shadow: 0px 0px 20px -12px #f3ba2f;
}
.st-pool-inner{
	overflow:hidden;
	position:relative;
	padding:25px;
	z-index:1;
}
.status{
	padding-left:30px;
	padding-right:10px;
	position:relative;
	line-height:24px;
	text-transform:uppercase;
	font-weight:500;
	font-size:13px;
	letter-spacing:1px;
	display:inline-block;
	margin-top:15px;
	
	border-radius:4px;
	box-shadow:0px 0px 20px -15px rgba(0,0,0,0.25);
}
.verified{
	color:#00e23B;
	background:rgba(168,251,190,0.3) url(../images/verified.png) no-repeat 6px 50%;
	background-size:16px;
}
.unverified{
	color:#ff1e00;
	background:rgba(255,181,171,0.3) url(../images/unverified.png) no-repeat 6px 50%;
	background-size:16px;
}
.stake-title{
	position:relative;
	margin-bottom:15px;
	float:left;
}
.stake-title img{
	max-height:50px;
	display:block;
	margin-bottom:10px;
}
.stake-title h3{
	text-transform:uppercase;
	letter-spacing:1px;
	font-size:20px;
}
.pool-earning{
	float:right;
	text-align:right;
}
.pool-earning h2{
	color:#4DCEED;
	line-height:1;
	font-size:42px;
}
.pool-earning h4{
	font-size:13px;
	color:#8B9198;
}
.staked-amount p span{
	font-weight:600;
}
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #4DCEED;
  background: linear-gradient(#4DCEED 0%, #28c0e4 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #28c0e4;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #28c0e4;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #28c0e4;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #28c0e4;
}
.apy{
	font-size:15px;
	line-height:25px;
	padding:0 12px;
	border-radius:4px;
	background:#f7f7f7;
	position:absolute;
	top:10px;
	right:20px;
	letter-spacing:1px;
	font-family: 'Teko', monospace;
	box-shadow:0 0 10px -6px rgba(0,0,0,0.3);
}
.progress-system{
	margin-bottom:20px;
}
.progress-system h5{
	font-size:13px;
	font-weight:600;
	margin-bottom:8px;
}
.token-claimed{
	position:relative;
	height:6px;
	border-radius:4px;
	background-color:#f7f7f7;
	overflow:hidden;
	margin-bottom:5px;
}
.token-claimed span{
	display:block;
	position:absolute;
	left:0;
	top:0;
	height:100%;
	background:#4DCEED;
}
.claim-detail h4{
	font-weight:600;
	line-height:18px;
	font-size:13px;
	color:#4DCEED;
	float:left;
}
.claim-detail h6{
	line-height:18px;
	font-size:13px;
	color:#8b9198;
	float:right;
}
.yield-box.clone{
	margin-bottom:15px;
}
.yield-box.clone table{
	margin:0;
}
.yield-box.clone tr{
	/*background-color:#f7f7f7;
	border-bottom:5px solid #fff;*/
}
.yield-box.clone tr th,
.yield-box.clone tr td{
	padding:3px 0;
}
.harvest-button{
	position:absolute;
	top:85px;
	right:25px;
}
	.harvest-button button {
		border: none;
		position: relative;
		display: inline-block;
		line-height: 24px;
		font-size: 16px;
		padding: 2px 10px 0 10px;
		border-radius: 3px;
		background-color: #4DCEED;
		font-family: 'Teko';
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: 0.3s all;
		-webkit-transition: 0.3s all;
		-moz-transition: 0.3s all;
	}
		.harvest-button button:hover {
			background-color: #181927;
			color: #fff;
		}
		.harvest-button button span {
			position: relative;
			z-index: 1;
		}
		.harvest-button button:first-child:after {
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			background-color: #00e23B;
			border-radius: 100%;
			position: absolute;
			left: -15px;
			top: calc(50% - 4px);
			animation: blink-animation 1s steps(5, start) infinite;
			-webkit-animation: blink-animation 1s steps(5, start) infinite;
		}
@keyframes blink-animation {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}
@-webkit-keyframes blink-animation {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}

.approve-btn.two-btn .btn{
	float:left;
	width:48%;
}
.approve-btn.two-btn .btn:last-child{
	float:right;
}
.unstake-wrap{
	position:absolute;
	width:100%;
	background-color:#181927;
	z-index:9;
	left:0;
	/*bottom:-100%;*/
	bottom:0px;
	padding:30px 20px;
	box-shadow:0px -10px 10px -13px rgb(0 0 0 / 30%);
	border-radius:0 0 4px 4px;
}
.unstake-wrap .form-control{
	border-color:rgba(255,255,255,0.3);
	color:#fff;
}
/*yield farming*/
.yield-box{
	position:relative;
	text-align:center;
}
.yield-box img{
	max-width:80px;
	display:block;
	margin:0 auto;
	margin-bottom:10px;
}
.yield-box h3{
	font-size:18px;
	letter-spacing:1px;
	text-transform:uppercase;
	margin-bottom:15px;
}
.yield-box table{
	width:100%;
	margin-bottom:15px;
}
.yield-box tr{
	
}
.yield-box tr th,
.yield-box tr td{
	vertical-align:middle;
	padding:5px;
}
.yield-box tr th{
	font-size:13px;
	font-weight:600;
	text-align:left;
}
.yield-box tr td{
	text-align:right;
	color:#8B9198;
}
.farming-deposit{
	margin-bottom:10px;
}
/*create pool*/
.create-box{
	position:relative;
	border-radius: 12px;
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 0px 20px -15px rgba(0,0,0,0.25);
}
.create-inner{
	padding-left:80px;
	position:relative;
}
.create-box span.icon{
	display:block;
	width:60px;
	height:60px;
	position:absolute;
	left:0;
	top:0;
}
.create-box span.icon svg{
	max-width:100%;
	height:auto;
	fill:#4DCEED;
}
.create-box h3{
	font-size:18px;
	text-transform:uppercase;
	letter-spacing:2px;
	line-height:24px;
}
.create-box h4{
	color:#8B9198;
	font-size:28px;
	line-height:36px;
}
.approve-pool{
	padding-top:20px;
	margin-top:30px;
	border-top:1px solid #dedede;
}
.approve-pool p{
	color:#8B9198;
	font-size:18px;
	margin-bottom:15px;
}
/*fees*/
.fee-box{
	position:relative;
	border-radius: 12px;
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 0px 20px -15px rgba(0,0,0,0.25);
}
.fee-box h3{
	font-size:20px;
	text-transform:uppercase;
	letter-spacing:2px;
	padding-bottom:10px;
	margin-bottom:15px;
	border-bottom:1px solid #dedede;
}
.mt-30{
	margin-top:30px !important;
}
.mb-20{
	margin-bottom:20px !important;
}
/*guide*/
.tab{
	display:none;
}
.tab.active{
	display:block;
}
.tab-links{
	width:420px;
	margin-bottom:30px;
	background:#fff;
	box-shadow: 0px 0px 20px -15px rgba(0,0,0,0.25);
	border-radius:30px;
}
.tab-links > li{
	float:left;
}
.tab-links > li a{
	display:block;
	padding:0 15px;
	line-height:40px;
	text-align:center;
	text-transform:uppercase;
	font-size:13px;
	font-weight:600;
	border-radius:30px;
	transition:0.4s all;
	-webkit-transition:0.4s all; 
	-moz-transition:0.4s all;
}
.tab-links > li.active a,
.tab-links > li a:hover{
	background-color:#4DCEED;
}
.guide .tab{
	padding:30px;
	background:#fff;
	box-shadow: 0px 0px 20px -15px rgba(0,0,0,0.25);
	border-radius: 12px;
}
.std p{
	font-size:15px;
	line-height:25px;
	margin-bottom:10px;
}
.std p:last-child{
	margin-bottom:0;
}
.std p a{
	color:#4DCEED;
}
.guide-box h2{
	font-size:30px;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom:30px;
}
.panel-default > .panel-heading{
	color: #181927;
    position: relative;
    background-color: #f7f7f7;
    border-color: #dedede;
	padding:0;
}
.panel-title{
    font-size: 18px;
    font-family: 'Teko';
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
}
.panel-title a, .panel-title a:active, .panel-title a:focus, .panel-title a:hover {
    display: block;
    line-height: 24px;
	position:relative;
	padding: 12px 16px;
}
.panel-title a:after {
    content: "\f068";
    height: 48px;
    width: 48px;
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Font Awesome 5 Free';
    display: block;
    font-size: 18px;
    text-align: center;
	color:#8B9198;
	line-height:48px;
	font-weight:900;
}
.panel-title a.collapsed:after{
	content: "\f067";
}
.video-container{
    overflow: hidden;
    position: relative;
    width:100%;
	border-radius:12px;
	border:10px solid rgba(255,255,255,0.1);
	box-shadow: 0px 0px 15px -5px rgb(0 0 0 / 50%);
}
.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.adding-steps-ul li{
	font-size:17px;
	position:relative;
	padding-left:18px;
	line-height:24px;
	margin-bottom:6px;
}
.adding-steps-ul li:last-child{
	margin-bottom:0;
}
.adding-steps-ul li b{
	color:#4DCEED;
	font-weight:600;
	position:absolute;
	left:0;
	top:0;
	display:block;
	line-height:22px;
}
.adding-steps-ul li a{
	text-decoration:underline;
	color:#4DCEED;
}
.bsc-guide-ul{
	list-style:disc;
	margin-left:20px;
}
.bsc-guide-ul li{
	font-size:18px;
	line-height:24px;
	margin-bottom:10px;
}
.bsc-guide-img{
	margin-top:15px;
}
/*ido details*/
.main-title h1.with-logo{
	line-height:40px;
	position:relative;
	padding-left:55px;
}
.main-title h1.with-logo span{
	display:block;
	position:absolute;
	left:0;
	top:0;
}
.main-title h1.with-logo span img{
	max-width:40px;
}
.ido-box{
	position:relative;
	padding:30px;
	border-radius:12px;
	background-color:#fff;
	box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px, rgba(25, 19, 38, 0.05) 0px 1px 1px;
}
.ido-amount{
	margin-bottom:15px;
	position:relative;
}
.ido-amount h3{
	font-weight:400;
	letter-spacing:1px;
	font-size:30px;
	color:#4DCEED;
	margin-top:5px;
}
.ido-amount h3 span{
	font-size:16px;
	color:#8b9198;
}
.ido-amount h4{
	text-transform:uppercase;
	font-size:14px;
	letter-spacing:1px;
	font-weight:600;
}
.ido-amount h5{
	font-size:13px;
	color:#8b9198;
	font-weight:600;
	position:absolute;
	right:0;
	top:0;
}
.starts-in{
	margin-bottom:15px;
}
.starts-in h4{
	text-transform:uppercase;
	font-size:14px;
	letter-spacing:1px;
	font-weight:600;
	margin-bottom:10px;
}
#demo > div{
	text-align:center;
	display:inline-block;
	margin-right:10px;
	width:55px;
	background-color:#4DCEED;
	border-radius:12px;
	overflow:hidden;
}
#demo > div > span{
	padding:8px 0;
	background:rgba(0,0,0,0.1);
	display:block;
	color:#181927;
	font-size:30px;
	line-height:1;
	font-weight:700;
}
#demo > div b{
	font-weight:700;
	font-size:10px;
	text-transform:uppercase;
	letter-spacing:1px;
	padding:6px 0;
	color:#181927;
	display:block;
}
.ido-description{
	padding-top:10px;
	position:relative;
}
.ido-description h3{
	text-transform:uppercase;
	letter-spacing:2px;
	font-size:24px;
	line-height:30px;
	margin-bottom:10px;
}
.short-description{
	margin-bottom:10px;
}
.short-description p{
	font-size:15px;
	line-height:24px;
	color:#8b9198;
}
.contract-address{
	margin-bottom:15px;
	position:relative;
	padding:5px 15px 5px 40px;
	border-radius:30px;
	background-color:#fff;
	box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px, rgba(25, 19, 38, 0.05) 0px 1px 1px;
}
.contract-address i{
	position:absolute;
	left:12px;
	top:50%;
	transform:translateY(-50%);
	-webkit-transform:translateY(-50%); 
	-moz-transform:translateY(-50%);
	font-size:20px;
	color:#8A92B2;
}
.contract-address  span{
	display:block;
	line-height:30px;
	font-size:15px;
}
.contract-address a{
	font-family:'Teko';
	font-size:16px;
	line-height:24px;
	padding:4px 15px 2px;
	display:block;
	background-color:#4DCEED;
	letter-spacing:1px;
	text-transform:uppercase;
	border-radius:30px;
	position:absolute;
	right:5px;
	top:5px;
}
.ido-des-bottom > a.btn{
	float:left;
}
.ido-des-additional{
	position:absolute;
	top:5px;
	right:0;
}
.ido-des-additional li{
	float:left;
	margin-left:15px;
}
.ido-des-additional li a{
	display:block;
	font-size:15px;
	width:30px;
	height:30px;
	border-radius:6px;
	line-height:30px;
	text-align:center;
	background-color:#4DCEED;
}
.token-information{
	margin-bottom:15px;
}
.token-information > li{
	float:left;
	margin-right:12px;
	padding-right:12px;
	border-right:1px solid #dedede; 
	line-height:1;
}
.token-information > li:last-child{
	margin-right:0;
	padding-right:0;
	border-right:0;
}
.token-information > li span{
	font-weight:600;
}
.ido-box .ribbon{
	top:-8px;
	left:-8px;
}
.additional-info{
	margin-top:60px;
	padding:25px;
	background-color:#fff;
	border-radius:12px;
	box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px, rgba(25, 19, 38, 0.05) 0px 1px 1px;
}
.additional-tabs{
	
}
.additional-tabs .tab-links{
	overflow:hidden;
	margin-left:auto;
	margin-right:auto;
	background-color:#f7f7f7;
}
.additional-tabs .tab-links > li{
	width:50%;
}
.additional-tabs .tab-links > li > a{
	display: block;
    padding: 0 15px;
    line-height:40px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
	letter-spacing:1px;
    border-radius:30px;
}
.dis-table{
	width:100%;
}
.dis-table td,
.dis-table th{
	vertical-align:middle;
	padding:12px 15px;
}
.dis-table thead th{
	font-size:16px;
	text-transform:uppercase;
	color:#8B9198;
	font-family:'Teko';
	letter-spacing:1px;
	font-weight:400;
	border-bottom:1px solid #dedede;
}
.dis-table tbody td{
	font-size:13px;
	font-weight:600;
}
.dis-table tbody td .btn{
	min-height:0;
	line-height:18px;
	font-size:13px;
	padding:7px 12px 5px;
}
.dis-table tbody tr:nth-child(even){
	background-color:#f7f7f7;
}
.pool-table{
	width:100%;
	border:1px solid #dedede;
}
.pool-table tr{
	border-bottom:1px solid #dedede;
}
.pool-table tr:last-child{
	border-bottom:0;
}
.pool-table td,
.pool-table th{
	vertical-align:middle;
	padding:12px 15px;
}
.pool-table th{
	font-size:16px;
	text-transform:uppercase;
	color:#8B9198;
	font-family:'Teko';
	letter-spacing:1px;
	font-weight:400;
}
.pool-table td{
	font-size:13px;
	font-weight:600;
}