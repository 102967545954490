@font-face {
  font-family: 'cryptofont';
  src:  url('fonts/cryptofont.eot?ea921x');
  src:  url('fonts/cryptofont.eot?ea921x#iefix') format('embedded-opentype'),
    url('fonts/cryptofont.ttf?ea921x') format('truetype'),
    url('fonts/cryptofont.woff?ea921x') format('woff'),
    url('fonts/cryptofont.svg?ea921x#cryptofont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.cf {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cryptofont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cf-0xbtc:before {
  content: "\e900";
}
.cf-1inch:before {
  content: "\e901";
}
.cf-1st:before {
  content: "\e902";
}
.cf-42:before {
  content: "\e903";
}
.cf-888:before {
  content: "\e904";
}
.cf-1337:before {
  content: "\e905";
}
.cf-aave:before {
  content: "\e906";
}
.cf-abbc:before {
  content: "\e907";
}
.cf-abt:before {
  content: "\e908";
}
.cf-aby:before {
  content: "\e909";
}
.cf-abyss:before {
  content: "\e90a";
}
.cf-ac3:before {
  content: "\e90b";
}
.cf-acat:before {
  content: "\e90c";
}
.cf-acc:before {
  content: "\e90d";
}
.cf-ace:before {
  content: "\e90e";
}
.cf-act:before {
  content: "\e90f";
}
.cf-ada:before {
  content: "\e910";
}
.cf-adb:before {
  content: "\e911";
}
.cf-adc:before {
  content: "\e912";
}
.cf-adh:before {
  content: "\e913";
}
.cf-adi:before {
  content: "\e914";
}
.cf-adst:before {
  content: "\e915";
}
.cf-adt:before {
  content: "\e916";
}
.cf-adx:before {
  content: "\e917";
}
.cf-ae:before {
  content: "\e918";
}
.cf-aeon:before {
  content: "\e919";
}
.cf-aergo:before {
  content: "\e91a";
}
.cf-aerm:before {
  content: "\e91b";
}
.cf-agi:before {
  content: "\e91c";
}
.cf-aht:before {
  content: "\e91d";
}
.cf-ai:before {
  content: "\e91e";
}
.cf-aib:before {
  content: "\e91f";
}
.cf-aid:before {
  content: "\e920";
}
.cf-aidoc:before {
  content: "\e921";
}
.cf-aion:before {
  content: "\e922";
}
.cf-air:before {
  content: "\e923";
}
.cf-ait:before {
  content: "\e924";
}
.cf-aix:before {
  content: "\e925";
}
.cf-akro:before {
  content: "\e926";
}
.cf-aleph:before {
  content: "\e927";
}
.cf-algo:before {
  content: "\e928";
}
.cf-alis:before {
  content: "\e929";
}
.cf-alqo:before {
  content: "\e92a";
}
.cf-alt:before {
  content: "\e92b";
}
.cf-alx:before {
  content: "\e92c";
}
.cf-amb:before {
  content: "\e92d";
}
.cf-amlt:before {
  content: "\e92e";
}
.cf-amm:before {
  content: "\e92f";
}
.cf-amn:before {
  content: "\e930";
}
.cf-amp:before {
  content: "\e931";
}
.cf-ampl:before {
  content: "\e932";
}
.cf-ams:before {
  content: "\e933";
}
.cf-anc:before {
  content: "\e934";
}
.cf-anj:before {
  content: "\e935";
}
.cf-ankr:before {
  content: "\e936";
}
.cf-ant:before {
  content: "\e937";
}
.cf-aph:before {
  content: "\e938";
}
.cf-apis:before {
  content: "\e939";
}
.cf-apl:before {
  content: "\e93a";
}
.cf-appc:before {
  content: "\e93b";
}
.cf-apr:before {
  content: "\e93c";
}
.cf-arc:before {
  content: "\e93d";
}
.cf-arct:before {
  content: "\e93e";
}
.cf-ardr:before {
  content: "\e93f";
}
.cf-arg:before {
  content: "\e940";
}
.cf-ark:before {
  content: "\e941";
}
.cf-arn:before {
  content: "\e942";
}
.cf-aro:before {
  content: "\e943";
}
.cf-arrr:before {
  content: "\e944";
}
.cf-art:before {
  content: "\e945";
}
.cf-ary:before {
  content: "\e946";
}
.cf-ast:before {
  content: "\e947";
}
.cf-astro:before {
  content: "\e948";
}
.cf-atb:before {
  content: "\e949";
}
.cf-atc:before {
  content: "\e94a";
}
.cf-atl:before {
  content: "\e94b";
}
.cf-atm:before {
  content: "\e94c";
}
.cf-atmc:before {
  content: "\e94d";
}
.cf-atmi:before {
  content: "\e94e";
}
.cf-atn:before {
  content: "\e94f";
}
.cf-atom:before {
  content: "\e950";
}
.cf-ats:before {
  content: "\e951";
}
.cf-atx:before {
  content: "\e952";
}
.cf-auc:before {
  content: "\e953";
}
.cf-aur:before {
  content: "\e954";
}
.cf-aura:before {
  content: "\e955";
}
.cf-avax:before {
  content: "\e956";
}
.cf-avt:before {
  content: "\e957";
}
.cf-awc:before {
  content: "\e958";
}
.cf-axp:before {
  content: "\e959";
}
.cf-b2b:before {
  content: "\e95a";
}
.cf-bac:before {
  content: "\e95b";
}
.cf-bal:before {
  content: "\e95c";
}
.cf-bam:before {
  content: "\e95d";
}
.cf-banca:before {
  content: "\e95e";
}
.cf-band:before {
  content: "\e95f";
}
.cf-bank:before {
  content: "\e960";
}
.cf-bat:before {
  content: "\e961";
}
.cf-bax:before {
  content: "\e962";
}
.cf-bay:before {
  content: "\e963";
}
.cf-bbi:before {
  content: "\e964";
}
.cf-bbn:before {
  content: "\e965";
}
.cf-bbo:before {
  content: "\e966";
}
.cf-bbp:before {
  content: "\e967";
}
.cf-bbr:before {
  content: "\e968";
}
.cf-bcc:before {
  content: "\e969";
}
.cf-bcd:before {
  content: "\e96a";
}
.cf-bch:before {
  content: "\e96b";
}
.cf-bcn:before {
  content: "\e96c";
}
.cf-bco:before {
  content: "\e96d";
}
.cf-bcpt:before {
  content: "\e96e";
}
.cf-bdg:before {
  content: "\e96f";
}
.cf-bdl:before {
  content: "\e970";
}
.cf-bee:before {
  content: "\e971";
}
.cf-bela:before {
  content: "\e972";
}
.cf-berry:before {
  content: "\e973";
}
.cf-bet:before {
  content: "\e974";
}
.cf-betr:before {
  content: "\e975";
}
.cf-bez:before {
  content: "\e976";
}
.cf-bf:before {
  content: "\e977";
}
.cf-bft:before {
  content: "\e978";
}
.cf-bigup:before {
  content: "\e979";
}
.cf-birds:before {
  content: "\e97a";
}
.cf-bis:before {
  content: "\e97b";
}
.cf-bitb:before {
  content: "\e97c";
}
.cf-bitg:before {
  content: "\e97d";
}
.cf-bits:before {
  content: "\e97e";
}
.cf-bitz:before {
  content: "\e97f";
}
.cf-bix:before {
  content: "\e980";
}
.cf-bkx:before {
  content: "\e981";
}
.cf-blc:before {
  content: "\e982";
}
.cf-blitz:before {
  content: "\e983";
}
.cf-blk:before {
  content: "\e984";
}
.cf-bln:before {
  content: "\e985";
}
.cf-block:before {
  content: "\e986";
}
.cf-blt:before {
  content: "\e987";
}
.cf-blu:before {
  content: "\e988";
}
.cf-blue:before {
  content: "\e989";
}
.cf-blz:before {
  content: "\e98a";
}
.cf-blz2:before {
  content: "\e98b";
}
.cf-bmc:before {
  content: "\e98c";
}
.cf-bmh:before {
  content: "\e98d";
}
.cf-bnb:before {
  content: "\e98e";
}
.cf-bnk:before {
  content: "\e98f";
}
.cf-bnt:before {
  content: "\e990";
}
.cf-bnty:before {
  content: "\e991";
}
.cf-boat:before {
  content: "\e992";
}
.cf-bon:before {
  content: "\e993";
}
.cf-bos:before {
  content: "\e994";
}
.cf-bot:before {
  content: "\e995";
}
.cf-box:before {
  content: "\e996";
}
.cf-bpt:before {
  content: "\e997";
}
.cf-bqx:before {
  content: "\e998";
}
.cf-brat:before {
  content: "\e999";
}
.cf-brd:before {
  content: "\e99a";
}
.cf-bsd:before {
  content: "\e99b";
}
.cf-bsm:before {
  content: "\e99c";
}
.cf-bta:before {
  content: "\e99d";
}
.cf-btc:before {
  content: "\e99e";
}
.cf-btc1:before {
  content: "\e99f";
}
.cf-btca:before {
  content: "\e9a0";
}
.cf-btcd:before {
  content: "\e9a1";
}
.cf-btcp:before {
  content: "\e9a2";
}
.cf-btdx:before {
  content: "\e9a3";
}
.cf-bte:before {
  content: "\e9a4";
}
.cf-btg:before {
  content: "\e9a5";
}
.cf-btm:before {
  content: "\e9a6";
}
.cf-btm2:before {
  content: "\e9a7";
}
.cf-btmx:before {
  content: "\e9a8";
}
.cf-bto:before {
  content: "\e9a9";
}
.cf-btrn:before {
  content: "\e9aa";
}
.cf-bts:before {
  content: "\e9ab";
}
.cf-btt:before {
  content: "\e9ac";
}
.cf-btu:before {
  content: "\e9ad";
}
.cf-btx:before {
  content: "\e9ae";
}
.cf-bu:before {
  content: "\e9af";
}
.cf-burst:before {
  content: "\e9b0";
}
.cf-busd:before {
  content: "\e9b1";
}
.cf-buzz:before {
  content: "\e9b2";
}
.cf-bwk:before {
  content: "\e9b3";
}
.cf-bwt:before {
  content: "\e9b4";
}
.cf-byc:before {
  content: "\e9b5";
}
.cf-bz:before {
  content: "\e9b6";
}
.cf-bznt:before {
  content: "\e9b7";
}
.cf-bzrx:before {
  content: "\e9b8";
}
.cf-c20:before {
  content: "\e9b9";
}
.cf-cake:before {
  content: "\e9ba";
}
.cf-can:before {
  content: "\e9bb";
}
.cf-candy:before {
  content: "\e9bc";
}
.cf-cann:before {
  content: "\e9bd";
}
.cf-capp:before {
  content: "\e9be";
}
.cf-car:before {
  content: "\e9bf";
}
.cf-carbon:before {
  content: "\e9c0";
}
.cf-card:before {
  content: "\e9c1";
}
.cf-cas:before {
  content: "\e9c2";
}
.cf-cat:before {
  content: "\e9c3";
}
.cf-cat2:before {
  content: "\e9c4";
}
.cf-caz:before {
  content: "\e9c5";
}
.cf-cbc:before {
  content: "\e9c6";
}
.cf-cbt:before {
  content: "\e9c7";
}
.cf-cbx:before {
  content: "\e9c8";
}
.cf-cccx:before {
  content: "\e9c9";
}
.cf-ccrb:before {
  content: "\e9ca";
}
.cf-cdn:before {
  content: "\e9cb";
}
.cf-cdt:before {
  content: "\e9cc";
}
.cf-ceek:before {
  content: "\e9cd";
}
.cf-cel:before {
  content: "\e9ce";
}
.cf-celr:before {
  content: "\e9cf";
}
.cf-cennz:before {
  content: "\e9d0";
}
.cf-cfi:before {
  content: "\e9d1";
}
.cf-chai:before {
  content: "\e9d2";
}
.cf-chat:before {
  content: "\e9d3";
}
.cf-chc:before {
  content: "\e9d4";
}
.cf-chp:before {
  content: "\e9d5";
}
.cf-chsb:before {
  content: "\e9d6";
}
.cf-chx:before {
  content: "\e9d7";
}
.cf-chz:before {
  content: "\e9d8";
}
.cf-cjt:before {
  content: "\e9d9";
}
.cf-ckb:before {
  content: "\e9da";
}
.cf-cl:before {
  content: "\e9db";
}
.cf-clam:before {
  content: "\e9dc";
}
.cf-cln:before {
  content: "\e9dd";
}
.cf-clo:before {
  content: "\e9de";
}
.cf-cloak:before {
  content: "\e9df";
}
.cf-clr:before {
  content: "\e9e0";
}
.cf-cmpco:before {
  content: "\e9e1";
}
.cf-cms:before {
  content: "\e9e2";
}
.cf-cmt:before {
  content: "\e9e3";
}
.cf-cnd:before {
  content: "\e9e4";
}
.cf-cnet:before {
  content: "\e9e5";
}
.cf-cnn:before {
  content: "\e9e6";
}
.cf-cnx:before {
  content: "\e9e7";
}
.cf-coal:before {
  content: "\e9e8";
}
.cf-cob:before {
  content: "\e9e9";
}
.cf-colx:before {
  content: "\e9ea";
}
.cf-comp:before {
  content: "\e9eb";
}
.cf-con:before {
  content: "\e9ec";
}
.cf-coni:before {
  content: "\e9ed";
}
.cf-cosm:before {
  content: "\e9ee";
}
.cf-coss:before {
  content: "\e9ef";
}
.cf-coti:before {
  content: "\e9f0";
}
.cf-cov:before {
  content: "\e9f1";
}
.cf-cova:before {
  content: "\e9f2";
}
.cf-coval:before {
  content: "\e9f3";
}
.cf-cpc:before {
  content: "\e9f4";
}
.cf-cpc2:before {
  content: "\e9f5";
}
.cf-cpn:before {
  content: "\e9f6";
}
.cf-cpx:before {
  content: "\e9f7";
}
.cf-cpy:before {
  content: "\e9f8";
}
.cf-crave:before {
  content: "\e9f9";
}
.cf-crb:before {
  content: "\e9fa";
}
.cf-crc:before {
  content: "\e9fb";
}
.cf-cre:before {
  content: "\e9fc";
}
.cf-crea:before {
  content: "\e9fd";
}
.cf-cream:before {
  content: "\e9fe";
}
.cf-cred:before {
  content: "\e9ff";
}
.cf-credo:before {
  content: "\ea00";
}
.cf-crm:before {
  content: "\ea01";
}
.cf-cro:before {
  content: "\ea02";
}
.cf-crop:before {
  content: "\ea03";
}
.cf-crpt:before {
  content: "\ea04";
}
.cf-crw:before {
  content: "\ea05";
}
.cf-cs:before {
  content: "\ea06";
}
.cf-csc:before {
  content: "\ea07";
}
.cf-csno:before {
  content: "\ea08";
}
.cf-ctr:before {
  content: "\ea09";
}
.cf-ctxc:before {
  content: "\ea0a";
}
.cf-cube:before {
  content: "\ea0b";
}
.cf-cure:before {
  content: "\ea0c";
}
.cf-cv:before {
  content: "\ea0d";
}
.cf-cvc:before {
  content: "\ea0e";
}
.cf-cvcoin:before {
  content: "\ea0f";
}
.cf-cvt:before {
  content: "\ea10";
}
.cf-cxo:before {
  content: "\ea11";
}
.cf-dadi:before {
  content: "\ea12";
}
.cf-dag:before {
  content: "\ea13";
}
.cf-dai:before {
  content: "\ea14";
}
.cf-dan:before {
  content: "\ea15";
}
.cf-dasc:before {
  content: "\ea16";
}
.cf-dash:before {
  content: "\ea17";
}
.cf-dat:before {
  content: "\ea18";
}
.cf-data:before {
  content: "\ea19";
}
.cf-datx:before {
  content: "\ea1a";
}
.cf-dax:before {
  content: "\ea1b";
}
.cf-daxx:before {
  content: "\ea1c";
}
.cf-day:before {
  content: "\ea1d";
}
.cf-dbc:before {
  content: "\ea1e";
}
.cf-dbet:before {
  content: "\ea1f";
}
.cf-dcc:before {
  content: "\ea20";
}
.cf-dcn:before {
  content: "\ea21";
}
.cf-dcr:before {
  content: "\ea22";
}
.cf-dct:before {
  content: "\ea23";
}
.cf-dcy:before {
  content: "\ea24";
}
.cf-ddd:before {
  content: "\ea25";
}
.cf-ddf:before {
  content: "\ea26";
}
.cf-deb:before {
  content: "\ea27";
}
.cf-dent:before {
  content: "\ea28";
}
.cf-dero:before {
  content: "\ea29";
}
.cf-deus:before {
  content: "\ea2a";
}
.cf-dev:before {
  content: "\ea2b";
}
.cf-dew:before {
  content: "\ea2c";
}
.cf-dft:before {
  content: "\ea2d";
}
.cf-dgb:before {
  content: "\ea2e";
}
.cf-dgd:before {
  content: "\ea2f";
}
.cf-dgpt:before {
  content: "\ea30";
}
.cf-dgtx:before {
  content: "\ea31";
}
.cf-dice:before {
  content: "\ea32";
}
.cf-dim:before {
  content: "\ea33";
}
.cf-dime:before {
  content: "\ea34";
}
.cf-divi:before {
  content: "\ea35";
}
.cf-divx:before {
  content: "\ea36";
}
.cf-dix:before {
  content: "\ea37";
}
.cf-dlisk:before {
  content: "\ea38";
}
.cf-dlt:before {
  content: "\ea39";
}
.cf-dmb:before {
  content: "\ea3a";
}
.cf-dmd:before {
  content: "\ea3b";
}
.cf-dml:before {
  content: "\ea3c";
}
.cf-dmt:before {
  content: "\ea3d";
}
.cf-dna:before {
  content: "\ea3e";
}
.cf-dnr:before {
  content: "\ea3f";
}
.cf-dnt:before {
  content: "\ea40";
}
.cf-dock:before {
  content: "\ea41";
}
.cf-doge:before {
  content: "\ea42";
}
.cf-dor:before {
  content: "\ea43";
}
.cf-dot:before {
  content: "\ea44";
}
.cf-dp:before {
  content: "\ea45";
}
.cf-dpy:before {
  content: "\ea46";
}
.cf-drgn:before {
  content: "\ea47";
}
.cf-drop:before {
  content: "\ea48";
}
.cf-drpu:before {
  content: "\ea49";
}
.cf-drt:before {
  content: "\ea4a";
}
.cf-drxne:before {
  content: "\ea4b";
}
.cf-dsh:before {
  content: "\ea4c";
}
.cf-dsr:before {
  content: "\ea4d";
}
.cf-dta:before {
  content: "\ea4e";
}
.cf-dtb:before {
  content: "\ea4f";
}
.cf-dth:before {
  content: "\ea50";
}
.cf-dtr:before {
  content: "\ea51";
}
.cf-dtrc:before {
  content: "\ea52";
}
.cf-dtx:before {
  content: "\ea53";
}
.cf-duo:before {
  content: "\ea54";
}
.cf-dxt:before {
  content: "\ea55";
}
.cf-earth:before {
  content: "\ea56";
}
.cf-ebtc:before {
  content: "\ea57";
}
.cf-eca:before {
  content: "\ea58";
}
.cf-ecash:before {
  content: "\ea59";
}
.cf-ecc:before {
  content: "\ea5a";
}
.cf-ecob:before {
  content: "\ea5b";
}
.cf-edg:before {
  content: "\ea5c";
}
.cf-edo:before {
  content: "\ea5d";
}
.cf-edr:before {
  content: "\ea5e";
}
.cf-edt:before {
  content: "\ea5f";
}
.cf-edu:before {
  content: "\ea60";
}
.cf-efl:before {
  content: "\ea61";
}
.cf-efx:before {
  content: "\ea62";
}
.cf-efyt:before {
  content: "\ea63";
}
.cf-egc:before {
  content: "\ea64";
}
.cf-egcc:before {
  content: "\ea65";
}
.cf-egld:before {
  content: "\ea66";
}
.cf-egt:before {
  content: "\ea67";
}
.cf-ekg:before {
  content: "\ea68";
}
.cf-eko:before {
  content: "\ea69";
}
.cf-ekt:before {
  content: "\ea6a";
}
.cf-el:before {
  content: "\ea6b";
}
.cf-ela:before {
  content: "\ea6c";
}
.cf-elec:before {
  content: "\ea6d";
}
.cf-elf:before {
  content: "\ea6e";
}
.cf-elix:before {
  content: "\ea6f";
}
.cf-ella:before {
  content: "\ea70";
}
.cf-eltcoin:before {
  content: "\ea71";
}
.cf-emb:before {
  content: "\ea72";
}
.cf-emc:before {
  content: "\ea73";
}
.cf-emc2:before {
  content: "\ea74";
}
.cf-eng:before {
  content: "\ea75";
}
.cf-enj:before {
  content: "\ea76";
}
.cf-enrg:before {
  content: "\ea77";
}
.cf-ent:before {
  content: "\ea78";
}
.cf-eos:before {
  content: "\ea79";
}
.cf-eosdac:before {
  content: "\ea7a";
}
.cf-epc:before {
  content: "\ea7b";
}
.cf-eql:before {
  content: "\ea7c";
}
.cf-eqt:before {
  content: "\ea7d";
}
.cf-erc:before {
  content: "\ea7e";
}
.cf-erc20:before {
  content: "\ea7f";
}
.cf-erd:before {
  content: "\ea80";
}
.cf-ero:before {
  content: "\ea81";
}
.cf-esd:before {
  content: "\ea82";
}
.cf-esp:before {
  content: "\ea83";
}
.cf-ess:before {
  content: "\ea84";
}
.cf-esz:before {
  content: "\ea85";
}
.cf-etbs:before {
  content: "\ea86";
}
.cf-etc:before {
  content: "\ea87";
}
.cf-eth:before {
  content: "\ea88";
}
.cf-etn:before {
  content: "\ea89";
}
.cf-etp:before {
  content: "\ea8a";
}
.cf-ett:before {
  content: "\ea8b";
}
.cf-etz:before {
  content: "\ea8c";
}
.cf-euc:before {
  content: "\ea8d";
}
.cf-evc:before {
  content: "\ea8e";
}
.cf-eve:before {
  content: "\ea8f";
}
.cf-evn:before {
  content: "\ea90";
}
.cf-evr:before {
  content: "\ea91";
}
.cf-evx:before {
  content: "\ea92";
}
.cf-excl:before {
  content: "\ea93";
}
.cf-exy:before {
  content: "\ea94";
}
.cf-face:before {
  content: "\ea95";
}
.cf-fair:before {
  content: "\ea96";
}
.cf-fct:before {
  content: "\ea97";
}
.cf-fdx:before {
  content: "\ea98";
}
.cf-fdz:before {
  content: "\ea99";
}
.cf-fet:before {
  content: "\ea9a";
}
.cf-fil:before {
  content: "\ea9b";
}
.cf-fjc:before {
  content: "\ea9c";
}
.cf-flash:before {
  content: "\ea9d";
}
.cf-flixx:before {
  content: "\ea9e";
}
.cf-flo:before {
  content: "\ea9f";
}
.cf-fluz:before {
  content: "\eaa0";
}
.cf-fnd:before {
  content: "\eaa1";
}
.cf-for:before {
  content: "\eaa2";
}
.cf-fota:before {
  content: "\eaa3";
}
.cf-frec:before {
  content: "\eaa4";
}
.cf-frm:before {
  content: "\eaa5";
}
.cf-frst:before {
  content: "\eaa6";
}
.cf-fsn:before {
  content: "\eaa7";
}
.cf-fst:before {
  content: "\eaa8";
}
.cf-ft:before {
  content: "\eaa9";
}
.cf-ftc:before {
  content: "\eaaa";
}
.cf-ftm:before {
  content: "\eaab";
}
.cf-ftt:before {
  content: "\eaac";
}
.cf-ftx:before {
  content: "\eaad";
}
.cf-fuel:before {
  content: "\eaae";
}
.cf-fun:before {
  content: "\eaaf";
}
.cf-func:before {
  content: "\eab0";
}
.cf-fxc:before {
  content: "\eab1";
}
.cf-fxt:before {
  content: "\eab2";
}
.cf-fyn:before {
  content: "\eab3";
}
.cf-game:before {
  content: "\eab4";
}
.cf-gat:before {
  content: "\eab5";
}
.cf-gb:before {
  content: "\eab6";
}
.cf-gbx:before {
  content: "\eab7";
}
.cf-gbyte:before {
  content: "\eab8";
}
.cf-gcc:before {
  content: "\eab9";
}
.cf-gcs:before {
  content: "\eaba";
}
.cf-gdc:before {
  content: "\eabb";
}
.cf-gem:before {
  content: "\eabc";
}
.cf-gen:before {
  content: "\eabd";
}
.cf-gene:before {
  content: "\eabe";
}
.cf-get:before {
  content: "\eabf";
}
.cf-getx:before {
  content: "\eac0";
}
.cf-gin:before {
  content: "\eac1";
}
.cf-gla:before {
  content: "\eac2";
}
.cf-gno:before {
  content: "\eac3";
}
.cf-gnt:before {
  content: "\eac4";
}
.cf-gnx:before {
  content: "\eac5";
}
.cf-go:before {
  content: "\eac6";
}
.cf-god:before {
  content: "\eac7";
}
.cf-golf:before {
  content: "\eac8";
}
.cf-golos:before {
  content: "\eac9";
}
.cf-good:before {
  content: "\eaca";
}
.cf-got:before {
  content: "\eacb";
}
.cf-grc:before {
  content: "\eacc";
}
.cf-grft:before {
  content: "\eacd";
}
.cf-grid:before {
  content: "\eace";
}
.cf-grin:before {
  content: "\eacf";
}
.cf-grlc:before {
  content: "\ead0";
}
.cf-grmd:before {
  content: "\ead1";
}
.cf-grn:before {
  content: "\ead2";
}
.cf-grs:before {
  content: "\ead3";
}
.cf-grt:before {
  content: "\ead4";
}
.cf-gsc:before {
  content: "\ead5";
}
.cf-gt:before {
  content: "\ead6";
}
.cf-gtc:before {
  content: "\ead7";
}
.cf-gto:before {
  content: "\ead8";
}
.cf-guess:before {
  content: "\ead9";
}
.cf-gup:before {
  content: "\eada";
}
.cf-gusd:before {
  content: "\eadb";
}
.cf-gvt:before {
  content: "\eadc";
}
.cf-gxs:before {
  content: "\eadd";
}
.cf-hac:before {
  content: "\eade";
}
.cf-hade:before {
  content: "\eadf";
}
.cf-hat:before {
  content: "\eae0";
}
.cf-hav:before {
  content: "\eae1";
}
.cf-hbar:before {
  content: "\eae2";
}
.cf-hbc:before {
  content: "\eae3";
}
.cf-hc:before {
  content: "\eae4";
}
.cf-heat:before {
  content: "\eae5";
}
.cf-hedg:before {
  content: "\eae6";
}
.cf-her:before {
  content: "\eae7";
}
.cf-hero:before {
  content: "\eae8";
}
.cf-hex:before {
  content: "\eae9";
}
.cf-hgt:before {
  content: "\eaea";
}
.cf-hire:before {
  content: "\eaeb";
}
.cf-hive:before {
  content: "\eaec";
}
.cf-hkn:before {
  content: "\eaed";
}
.cf-hlc:before {
  content: "\eaee";
}
.cf-hmc:before {
  content: "\eaef";
}
.cf-hmq:before {
  content: "\eaf0";
}
.cf-hot:before {
  content: "\eaf1";
}
.cf-hot2:before {
  content: "\eaf2";
}
.cf-hpb:before {
  content: "\eaf3";
}
.cf-hpc:before {
  content: "\eaf4";
}
.cf-hqx:before {
  content: "\eaf5";
}
.cf-hsr:before {
  content: "\eaf6";
}
.cf-hst:before {
  content: "\eaf7";
}
.cf-ht:before {
  content: "\eaf8";
}
.cf-huc:before {
  content: "\eaf9";
}
.cf-hum:before {
  content: "\eafa";
}
.cf-hur:before {
  content: "\eafb";
}
.cf-husd:before {
  content: "\eafc";
}
.cf-hush:before {
  content: "\eafd";
}
.cf-hvn:before {
  content: "\eafe";
}
.cf-hxx:before {
  content: "\eaff";
}
.cf-hydro:before {
  content: "\eb00";
}
.cf-hyn:before {
  content: "\eb01";
}
.cf-ic:before {
  content: "\eb02";
}
.cf-icn:before {
  content: "\eb03";
}
.cf-icon:before {
  content: "\eb04";
}
.cf-icx:before {
  content: "\eb05";
}
.cf-idex:before {
  content: "\eb06";
}
.cf-idh:before {
  content: "\eb07";
}
.cf-idt:before {
  content: "\eb08";
}
.cf-idxm:before {
  content: "\eb09";
}
.cf-ieth:before {
  content: "\eb0a";
}
.cf-ift:before {
  content: "\eb0b";
}
.cf-ignis:before {
  content: "\eb0c";
}
.cf-iht:before {
  content: "\eb0d";
}
.cf-iic:before {
  content: "\eb0e";
}
.cf-inb:before {
  content: "\eb0f";
}
.cf-inc:before {
  content: "\eb10";
}
.cf-incnt:before {
  content: "\eb11";
}
.cf-ind:before {
  content: "\eb12";
}
.cf-infx:before {
  content: "\eb13";
}
.cf-ing:before {
  content: "\eb14";
}
.cf-ink:before {
  content: "\eb15";
}
.cf-inn:before {
  content: "\eb16";
}
.cf-inpay:before {
  content: "\eb17";
}
.cf-ins:before {
  content: "\eb18";
}
.cf-insn:before {
  content: "\eb19";
}
.cf-instar:before {
  content: "\eb1a";
}
.cf-insur:before {
  content: "\eb1b";
}
.cf-int:before {
  content: "\eb1c";
}
.cf-inv:before {
  content: "\eb1d";
}
.cf-inxt:before {
  content: "\eb1e";
}
.cf-ioc:before {
  content: "\eb1f";
}
.cf-ion:before {
  content: "\eb20";
}
.cf-iop:before {
  content: "\eb21";
}
.cf-iost:before {
  content: "\eb22";
}
.cf-iota:before {
  content: "\eb23";
}
.cf-iotx:before {
  content: "\eb24";
}
.cf-ipl:before {
  content: "\eb25";
}
.cf-iq:before {
  content: "\eb26";
}
.cf-iqt:before {
  content: "\eb27";
}
.cf-iris:before {
  content: "\eb28";
}
.cf-isl:before {
  content: "\eb29";
}
.cf-itc:before {
  content: "\eb2a";
}
.cf-itns:before {
  content: "\eb2b";
}
.cf-itt:before {
  content: "\eb2c";
}
.cf-ivy:before {
  content: "\eb2d";
}
.cf-ixc:before {
  content: "\eb2e";
}
.cf-ixt:before {
  content: "\eb2f";
}
.cf-j8t:before {
  content: "\eb30";
}
.cf-jc:before {
  content: "\eb31";
}
.cf-jet:before {
  content: "\eb32";
}
.cf-jew:before {
  content: "\eb33";
}
.cf-jnt:before {
  content: "\eb34";
}
.cf-jrt:before {
  content: "\eb35";
}
.cf-jst:before {
  content: "\eb36";
}
.cf-karma:before {
  content: "\eb37";
}
.cf-kava:before {
  content: "\eb38";
}
.cf-kb3:before {
  content: "\eb39";
}
.cf-kbr:before {
  content: "\eb3a";
}
.cf-kcs:before {
  content: "\eb3b";
}
.cf-key:before {
  content: "\eb3c";
}
.cf-key2:before {
  content: "\eb3d";
}
.cf-kick:before {
  content: "\eb3e";
}
.cf-kin:before {
  content: "\eb3f";
}
.cf-kln:before {
  content: "\eb40";
}
.cf-kmd:before {
  content: "\eb41";
}
.cf-knc:before {
  content: "\eb42";
}
.cf-kobo:before {
  content: "\eb43";
}
.cf-kore:before {
  content: "\eb44";
}
.cf-krb:before {
  content: "\eb45";
}
.cf-krm:before {
  content: "\eb46";
}
.cf-krone:before {
  content: "\eb47";
}
.cf-ksm:before {
  content: "\eb48";
}
.cf-kurt:before {
  content: "\eb49";
}
.cf-la:before {
  content: "\eb4a";
}
.cf-lala:before {
  content: "\eb4b";
}
.cf-lamb:before {
  content: "\eb4c";
}
.cf-latx:before {
  content: "\eb4d";
}
.cf-lba:before {
  content: "\eb4e";
}
.cf-lbc:before {
  content: "\eb4f";
}
.cf-lbtc:before {
  content: "\eb50";
}
.cf-lcc:before {
  content: "\eb51";
}
.cf-ldc:before {
  content: "\eb52";
}
.cf-lend:before {
  content: "\eb53";
}
.cf-leo:before {
  content: "\eb54";
}
.cf-let:before {
  content: "\eb55";
}
.cf-lev:before {
  content: "\eb56";
}
.cf-lgd:before {
  content: "\eb57";
}
.cf-lgo:before {
  content: "\eb58";
}
.cf-linda:before {
  content: "\eb59";
}
.cf-link:before {
  content: "\eb5a";
}
.cf-linx:before {
  content: "\eb5b";
}
.cf-live:before {
  content: "\eb5c";
}
.cf-lkk:before {
  content: "\eb5d";
}
.cf-lky:before {
  content: "\eb5e";
}
.cf-lmc:before {
  content: "\eb5f";
}
.cf-lnc:before {
  content: "\eb60";
}
.cf-lnd:before {
  content: "\eb61";
}
.cf-loc:before {
  content: "\eb62";
}
.cf-loci:before {
  content: "\eb63";
}
.cf-log:before {
  content: "\eb64";
}
.cf-loki:before {
  content: "\eb65";
}
.cf-loom:before {
  content: "\eb66";
}
.cf-lpt:before {
  content: "\eb67";
}
.cf-lqd:before {
  content: "\eb68";
}
.cf-lrc:before {
  content: "\eb69";
}
.cf-lsk:before {
  content: "\eb6a";
}
.cf-ltc:before {
  content: "\eb6b";
}
.cf-lto:before {
  content: "\eb6c";
}
.cf-lun:before {
  content: "\eb6d";
}
.cf-luna:before {
  content: "\eb6e";
}
.cf-lux:before {
  content: "\eb6f";
}
.cf-lwf:before {
  content: "\eb70";
}
.cf-lxt:before {
  content: "\eb71";
}
.cf-lyl:before {
  content: "\eb72";
}
.cf-lym:before {
  content: "\eb73";
}
.cf-mag:before {
  content: "\eb74";
}
.cf-maid:before {
  content: "\eb75";
}
.cf-man:before {
  content: "\eb76";
}
.cf-mana-old:before {
  content: "\eb77";
}
.cf-mana:before {
  content: "\eb78";
}
.cf-matic:before {
  content: "\eb79";
}
.cf-max:before {
  content: "\eb7a";
}
.cf-mbc:before {
  content: "\eb7b";
}
.cf-mbrs:before {
  content: "\eb7c";
}
.cf-mcap:before {
  content: "\eb7d";
}
.cf-mco:before {
  content: "\eb7e";
}
.cf-mcx:before {
  content: "\eb7f";
}
.cf-mda:before {
  content: "\eb80";
}
.cf-mdc:before {
  content: "\eb81";
}
.cf-mds:before {
  content: "\eb82";
}
.cf-mdt:before {
  content: "\eb83";
}
.cf-mec:before {
  content: "\eb84";
}
.cf-med:before {
  content: "\eb85";
}
.cf-medic:before {
  content: "\eb86";
}
.cf-medx:before {
  content: "\eb87";
}
.cf-meet:before {
  content: "\eb88";
}
.cf-mer:before {
  content: "\eb89";
}
.cf-met:before {
  content: "\eb8a";
}
.cf-mfg:before {
  content: "\eb8b";
}
.cf-mft:before {
  content: "\eb8c";
}
.cf-mgo:before {
  content: "\eb8d";
}
.cf-minex:before {
  content: "\eb8e";
}
.cf-mint:before {
  content: "\eb8f";
}
.cf-mith:before {
  content: "\eb90";
}
.cf-mitx:before {
  content: "\eb91";
}
.cf-mkr:before {
  content: "\eb92";
}
.cf-mln:before {
  content: "\eb93";
}
.cf-mne:before {
  content: "\eb94";
}
.cf-mntp:before {
  content: "\eb95";
}
.cf-mnx:before {
  content: "\eb96";
}
.cf-mnx2:before {
  content: "\eb97";
}
.cf-moac:before {
  content: "\eb98";
}
.cf-mobi:before {
  content: "\eb99";
}
.cf-mod:before {
  content: "\eb9a";
}
.cf-mof:before {
  content: "\eb9b";
}
.cf-moin:before {
  content: "\eb9c";
}
.cf-mojo:before {
  content: "\eb9d";
}
.cf-mona:before {
  content: "\eb9e";
}
.cf-moon:before {
  content: "\eb9f";
}
.cf-morph:before {
  content: "\eba0";
}
.cf-mot:before {
  content: "\eba1";
}
.cf-mrk:before {
  content: "\eba2";
}
.cf-mrq:before {
  content: "\eba3";
}
.cf-mscn:before {
  content: "\eba4";
}
.cf-msp:before {
  content: "\eba5";
}
.cf-msr:before {
  content: "\eba6";
}
.cf-mta:before {
  content: "\eba7";
}
.cf-mtc:before {
  content: "\eba8";
}
.cf-mth:before {
  content: "\eba9";
}
.cf-mtl:before {
  content: "\ebaa";
}
.cf-mtn:before {
  content: "\ebab";
}
.cf-mtx:before {
  content: "\ebac";
}
.cf-mue:before {
  content: "\ebad";
}
.cf-muse:before {
  content: "\ebae";
}
.cf-music:before {
  content: "\ebaf";
}
.cf-mvc:before {
  content: "\ebb0";
}
.cf-mvp:before {
  content: "\ebb1";
}
.cf-mwat:before {
  content: "\ebb2";
}
.cf-mwc:before {
  content: "\ebb3";
}
.cf-mxm:before {
  content: "\ebb4";
}
.cf-myb:before {
  content: "\ebb5";
}
.cf-myst:before {
  content: "\ebb6";
}
.cf-nanj:before {
  content: "\ebb7";
}
.cf-nano:before {
  content: "\ebb8";
}
.cf-nanox:before {
  content: "\ebb9";
}
.cf-nas:before {
  content: "\ebba";
}
.cf-nav:before {
  content: "\ebbb";
}
.cf-navi:before {
  content: "\ebbc";
}
.cf-nbai:before {
  content: "\ebbd";
}
.cf-ncash:before {
  content: "\ebbe";
}
.cf-nct:before {
  content: "\ebbf";
}
.cf-near:before {
  content: "\ebc0";
}
.cf-nebl:before {
  content: "\ebc1";
}
.cf-nec:before {
  content: "\ebc2";
}
.cf-neo:before {
  content: "\ebc3";
}
.cf-neos:before {
  content: "\ebc4";
}
.cf-net:before {
  content: "\ebc5";
}
.cf-neu:before {
  content: "\ebc6";
}
.cf-new:before {
  content: "\ebc7";
}
.cf-newb:before {
  content: "\ebc8";
}
.cf-nex:before {
  content: "\ebc9";
}
.cf-nexo:before {
  content: "\ebca";
}
.cf-nexxo:before {
  content: "\ebcb";
}
.cf-ngc:before {
  content: "\ebcc";
}
.cf-nio:before {
  content: "\ebcd";
}
.cf-nkn:before {
  content: "\ebce";
}
.cf-nlc2:before {
  content: "\ebcf";
}
.cf-nlg:before {
  content: "\ebd0";
}
.cf-nlx:before {
  content: "\ebd1";
}
.cf-nmc:before {
  content: "\ebd2";
}
.cf-nmr:before {
  content: "\ebd3";
}
.cf-nms:before {
  content: "\ebd4";
}
.cf-nobl:before {
  content: "\ebd5";
}
.cf-nper:before {
  content: "\ebd6";
}
.cf-npxs:before {
  content: "\ebd7";
}
.cf-nrg:before {
  content: "\ebd8";
}
.cf-nrve:before {
  content: "\ebd9";
}
.cf-nsr:before {
  content: "\ebda";
}
.cf-ntk:before {
  content: "\ebdb";
}
.cf-ntrn:before {
  content: "\ebdc";
}
.cf-nuko:before {
  content: "\ebdd";
}
.cf-nuls:before {
  content: "\ebde";
}
.cf-nvst:before {
  content: "\ebdf";
}
.cf-nxs:before {
  content: "\ebe0";
}
.cf-nxt:before {
  content: "\ebe1";
}
.cf-nyan:before {
  content: "\ebe2";
}
.cf-oag:before {
  content: "\ebe3";
}
.cf-oax:before {
  content: "\ebe4";
}
.cf-obits:before {
  content: "\ebe5";
}
.cf-oc:before {
  content: "\ebe6";
}
.cf-occ:before {
  content: "\ebe7";
}
.cf-ocean:before {
  content: "\ebe8";
}
.cf-ocn:before {
  content: "\ebe9";
}
.cf-oct:before {
  content: "\ebea";
}
.cf-ode:before {
  content: "\ebeb";
}
.cf-odn:before {
  content: "\ebec";
}
.cf-ogn:before {
  content: "\ebed";
}
.cf-ok:before {
  content: "\ebee";
}
.cf-okb:before {
  content: "\ebef";
}
.cf-olt:before {
  content: "\ebf0";
}
.cf-omg:before {
  content: "\ebf1";
}
.cf-omni:before {
  content: "\ebf2";
}
.cf-omx:before {
  content: "\ebf3";
}
.cf-one:before {
  content: "\ebf4";
}
.cf-onion:before {
  content: "\ebf5";
}
.cf-ont:before {
  content: "\ebf6";
}
.cf-onx:before {
  content: "\ebf7";
}
.cf-oot:before {
  content: "\ebf8";
}
.cf-opc:before {
  content: "\ebf9";
}
.cf-open:before {
  content: "\ebfa";
}
.cf-opt:before {
  content: "\ebfb";
}
.cf-orbs:before {
  content: "\ebfc";
}
.cf-ore:before {
  content: "\ebfd";
}
.cf-ori:before {
  content: "\ebfe";
}
.cf-orme:before {
  content: "\ebff";
}
.cf-ost:before {
  content: "\ec00";
}
.cf-otn:before {
  content: "\ec01";
}
.cf-otx:before {
  content: "\ec02";
}
.cf-ovc:before {
  content: "\ec03";
}
.cf-oxt:before {
  content: "\ec04";
}
.cf-oxy:before {
  content: "\ec05";
}
.cf-pac:before {
  content: "\ec06";
}
.cf-pai:before {
  content: "\ec07";
}
.cf-pak:before {
  content: "\ec08";
}
.cf-pal:before {
  content: "\ec09";
}
.cf-pareto:before {
  content: "\ec0a";
}
.cf-part:before {
  content: "\ec0b";
}
.cf-pasc:before {
  content: "\ec0c";
}
.cf-pasl:before {
  content: "\ec0d";
}
.cf-pat:before {
  content: "\ec0e";
}
.cf-pax:before {
  content: "\ec0f";
}
.cf-pay:before {
  content: "\ec10";
}
.cf-payx:before {
  content: "\ec11";
}
.cf-pbl:before {
  content: "\ec12";
}
.cf-pbt:before {
  content: "\ec13";
}
.cf-pcl:before {
  content: "\ec14";
}
.cf-pcn:before {
  content: "\ec15";
}
.cf-perl:before {
  content: "\ec16";
}
.cf-pfr:before {
  content: "\ec17";
}
.cf-pho:before {
  content: "\ec18";
}
.cf-phr:before {
  content: "\ec19";
}
.cf-phs:before {
  content: "\ec1a";
}
.cf-ping:before {
  content: "\ec1b";
}
.cf-pink:before {
  content: "\ec1c";
}
.cf-pirl:before {
  content: "\ec1d";
}
.cf-pivx:before {
  content: "\ec1e";
}
.cf-pix:before {
  content: "\ec1f";
}
.cf-pkc:before {
  content: "\ec20";
}
.cf-pkt:before {
  content: "\ec21";
}
.cf-plan:before {
  content: "\ec22";
}
.cf-play:before {
  content: "\ec23";
}
.cf-plbt:before {
  content: "\ec24";
}
.cf-plc:before {
  content: "\ec25";
}
.cf-plr:before {
  content: "\ec26";
}
.cf-plu:before {
  content: "\ec27";
}
.cf-plx:before {
  content: "\ec28";
}
.cf-pmnt:before {
  content: "\ec29";
}
.cf-pnt:before {
  content: "\ec2a";
}
.cf-poa:before {
  content: "\ec2b";
}
.cf-poe:before {
  content: "\ec2c";
}
.cf-polis:before {
  content: "\ec2d";
}
.cf-poll:before {
  content: "\ec2e";
}
.cf-poly:before {
  content: "\ec2f";
}
.cf-pos:before {
  content: "\ec30";
}
.cf-post:before {
  content: "\ec31";
}
.cf-posw:before {
  content: "\ec32";
}
.cf-pot:before {
  content: "\ec33";
}
.cf-powr:before {
  content: "\ec34";
}
.cf-ppc:before {
  content: "\ec35";
}
.cf-ppp:before {
  content: "\ec36";
}
.cf-ppt:before {
  content: "\ec37";
}
.cf-ppy:before {
  content: "\ec38";
}
.cf-pra:before {
  content: "\ec39";
}
.cf-pre:before {
  content: "\ec3a";
}
.cf-prg:before {
  content: "\ec3b";
}
.cf-prl:before {
  content: "\ec3c";
}
.cf-pro:before {
  content: "\ec3d";
}
.cf-proc:before {
  content: "\ec3e";
}
.cf-prq:before {
  content: "\ec3f";
}
.cf-prs:before {
  content: "\ec40";
}
.cf-pst:before {
  content: "\ec41";
}
.cf-ptc:before {
  content: "\ec42";
}
.cf-ptoy:before {
  content: "\ec43";
}
.cf-pura:before {
  content: "\ec44";
}
.cf-pure:before {
  content: "\ec45";
}
.cf-pwr:before {
  content: "\ec46";
}
.cf-pylnt:before {
  content: "\ec47";
}
.cf-pzm:before {
  content: "\ec48";
}
.cf-qash:before {
  content: "\ec49";
}
.cf-qau:before {
  content: "\ec4a";
}
.cf-qbic:before {
  content: "\ec4b";
}
.cf-qbit:before {
  content: "\ec4c";
}
.cf-qbt:before {
  content: "\ec4d";
}
.cf-qkc:before {
  content: "\ec4e";
}
.cf-qlc:before {
  content: "\ec4f";
}
.cf-qlr:before {
  content: "\ec50";
}
.cf-qnt:before {
  content: "\ec51";
}
.cf-qora:before {
  content: "\ec52";
}
.cf-qrk:before {
  content: "\ec53";
}
.cf-qsp:before {
  content: "\ec54";
}
.cf-qtum:before {
  content: "\ec55";
}
.cf-qun:before {
  content: "\ec56";
}
.cf-qvt:before {
  content: "\ec57";
}
.cf-qwark:before {
  content: "\ec58";
}
.cf-r:before {
  content: "\ec59";
}
.cf-rads:before {
  content: "\ec5a";
}
.cf-rae:before {
  content: "\ec5b";
}
.cf-rain:before {
  content: "\ec5c";
}
.cf-rbt:before {
  content: "\ec5d";
}
.cf-rby:before {
  content: "\ec5e";
}
.cf-rcn:before {
  content: "\ec5f";
}
.cf-rdd:before {
  content: "\ec60";
}
.cf-rdn:before {
  content: "\ec61";
}
.cf-real:before {
  content: "\ec62";
}
.cf-rebl:before {
  content: "\ec63";
}
.cf-red:before {
  content: "\ec64";
}
.cf-ree:before {
  content: "\ec65";
}
.cf-ref:before {
  content: "\ec66";
}
.cf-rem:before {
  content: "\ec67";
}
.cf-ren:before {
  content: "\ec68";
}
.cf-rep:before {
  content: "\ec69";
}
.cf-repo:before {
  content: "\ec6a";
}
.cf-req:before {
  content: "\ec6b";
}
.cf-rex:before {
  content: "\ec6c";
}
.cf-rfox:before {
  content: "\ec6d";
}
.cf-rfr:before {
  content: "\ec6e";
}
.cf-rhoc:before {
  content: "\ec6f";
}
.cf-ric:before {
  content: "\ec70";
}
.cf-rif:before {
  content: "\ec71";
}
.cf-rise:before {
  content: "\ec72";
}
.cf-rkt:before {
  content: "\ec73";
}
.cf-rlc:before {
  content: "\ec74";
}
.cf-rmt:before {
  content: "\ec75";
}
.cf-rnt:before {
  content: "\ec76";
}
.cf-rntb:before {
  content: "\ec77";
}
.cf-rpx:before {
  content: "\ec78";
}
.cf-rsr:before {
  content: "\ec79";
}
.cf-ruff:before {
  content: "\ec7a";
}
.cf-rune:before {
  content: "\ec7b";
}
.cf-rup:before {
  content: "\ec7c";
}
.cf-rvn:before {
  content: "\ec7d";
}
.cf-rvt:before {
  content: "\ec7e";
}
.cf-s:before {
  content: "\ec7f";
}
.cf-safex:before {
  content: "\ec80";
}
.cf-saga:before {
  content: "\ec81";
}
.cf-sai:before {
  content: "\ec82";
}
.cf-sal:before {
  content: "\ec83";
}
.cf-salt:before {
  content: "\ec84";
}
.cf-san:before {
  content: "\ec85";
}
.cf-sbtc:before {
  content: "\ec86";
}
.cf-scl:before {
  content: "\ec87";
}
.cf-scrl:before {
  content: "\ec88";
}
.cf-scs:before {
  content: "\ec89";
}
.cf-sct:before {
  content: "\ec8a";
}
.cf-sdc:before {
  content: "\ec8b";
}
.cf-sdrn:before {
  content: "\ec8c";
}
.cf-seele:before {
  content: "\ec8d";
}
.cf-sem:before {
  content: "\ec8e";
}
.cf-sen:before {
  content: "\ec8f";
}
.cf-senc:before {
  content: "\ec90";
}
.cf-send:before {
  content: "\ec91";
}
.cf-sense:before {
  content: "\ec92";
}
.cf-sent:before {
  content: "\ec93";
}
.cf-seq:before {
  content: "\ec94";
}
.cf-seth:before {
  content: "\ec95";
}
.cf-sexc:before {
  content: "\ec96";
}
.cf-sfc:before {
  content: "\ec97";
}
.cf-sgcc:before {
  content: "\ec98";
}
.cf-sgn:before {
  content: "\ec99";
}
.cf-sgr:before {
  content: "\ec9a";
}
.cf-shift:before {
  content: "\ec9b";
}
.cf-ship:before {
  content: "\ec9c";
}
.cf-shl:before {
  content: "\ec9d";
}
.cf-shorty:before {
  content: "\ec9e";
}
.cf-shp:before {
  content: "\ec9f";
}
.cf-shuf:before {
  content: "\eca0";
}
.cf-sia:before {
  content: "\eca1";
}
.cf-sib:before {
  content: "\eca2";
}
.cf-sigt:before {
  content: "\eca3";
}
.cf-skb:before {
  content: "\eca4";
}
.cf-skin:before {
  content: "\eca5";
}
.cf-skl:before {
  content: "\eca6";
}
.cf-skm:before {
  content: "\eca7";
}
.cf-sky:before {
  content: "\eca8";
}
.cf-slg:before {
  content: "\eca9";
}
.cf-slr:before {
  content: "\ecaa";
}
.cf-sls:before {
  content: "\ecab";
}
.cf-slt:before {
  content: "\ecac";
}
.cf-smart:before {
  content: "\ecad";
}
.cf-smc:before {
  content: "\ecae";
}
.cf-sms:before {
  content: "\ecaf";
}
.cf-smt:before {
  content: "\ecb0";
}
.cf-snc:before {
  content: "\ecb1";
}
.cf-sngls:before {
  content: "\ecb2";
}
.cf-snm:before {
  content: "\ecb3";
}
.cf-snt:before {
  content: "\ecb4";
}
.cf-sntr:before {
  content: "\ecb5";
}
.cf-snx:before {
  content: "\ecb6";
}
.cf-soar:before {
  content: "\ecb7";
}
.cf-soc:before {
  content: "\ecb8";
}
.cf-soil:before {
  content: "\ecb9";
}
.cf-sol:before {
  content: "\ecba";
}
.cf-soul:before {
  content: "\ecbb";
}
.cf-spank:before {
  content: "\ecbc";
}
.cf-spd:before {
  content: "\ecbd";
}
.cf-spd2:before {
  content: "\ecbe";
}
.cf-spf:before {
  content: "\ecbf";
}
.cf-sphtx:before {
  content: "\ecc0";
}
.cf-spk:before {
  content: "\ecc1";
}
.cf-spn:before {
  content: "\ecc2";
}
.cf-spr:before {
  content: "\ecc3";
}
.cf-sprts:before {
  content: "\ecc4";
}
.cf-src:before {
  content: "\ecc5";
}
.cf-srcoin:before {
  content: "\ecc6";
}
.cf-srm:before {
  content: "\ecc7";
}
.cf-srn:before {
  content: "\ecc8";
}
.cf-ss:before {
  content: "\ecc9";
}
.cf-ssp:before {
  content: "\ecca";
}
.cf-sss:before {
  content: "\eccb";
}
.cf-sta:before {
  content: "\eccc";
}
.cf-stac:before {
  content: "\eccd";
}
.cf-stak:before {
  content: "\ecce";
}
.cf-stake:before {
  content: "\eccf";
}
.cf-start:before {
  content: "\ecd0";
}
.cf-stc:before {
  content: "\ecd1";
}
.cf-steem:before {
  content: "\ecd2";
}
.cf-stn2:before {
  content: "\ecd3";
}
.cf-storj:before {
  content: "\ecd4";
}
.cf-storm:before {
  content: "\ecd5";
}
.cf-stq:before {
  content: "\ecd6";
}
.cf-strat:before {
  content: "\ecd7";
}
.cf-strc:before {
  content: "\ecd8";
}
.cf-stv:before {
  content: "\ecd9";
}
.cf-stx:before {
  content: "\ecda";
}
.cf-sub:before {
  content: "\ecdb";
}
.cf-sumo:before {
  content: "\ecdc";
}
.cf-super:before {
  content: "\ecdd";
}
.cf-suqa:before {
  content: "\ecde";
}
.cf-sur:before {
  content: "\ecdf";
}
.cf-svh:before {
  content: "\ece0";
}
.cf-swftc:before {
  content: "\ece1";
}
.cf-swift:before {
  content: "\ece2";
}
.cf-swm:before {
  content: "\ece3";
}
.cf-swt:before {
  content: "\ece4";
}
.cf-swtc:before {
  content: "\ece5";
}
.cf-swth:before {
  content: "\ece6";
}
.cf-sxdt:before {
  content: "\ece7";
}
.cf-sxp:before {
  content: "\ece8";
}
.cf-sys:before {
  content: "\ece9";
}
.cf-taas:before {
  content: "\ecea";
}
.cf-tau:before {
  content: "\eceb";
}
.cf-tbar:before {
  content: "\ecec";
}
.cf-tbx:before {
  content: "\eced";
}
.cf-tct:before {
  content: "\ecee";
}
.cf-tdx:before {
  content: "\ecef";
}
.cf-tel:before {
  content: "\ecf0";
}
.cf-ten:before {
  content: "\ecf1";
}
.cf-tes:before {
  content: "\ecf2";
}
.cf-tfd:before {
  content: "\ecf3";
}
.cf-tfuel:before {
  content: "\ecf4";
}
.cf-thc:before {
  content: "\ecf5";
}
.cf-theta:before {
  content: "\ecf6";
}
.cf-thr:before {
  content: "\ecf7";
}
.cf-tie:before {
  content: "\ecf8";
}
.cf-tig:before {
  content: "\ecf9";
}
.cf-time:before {
  content: "\ecfa";
}
.cf-tio:before {
  content: "\ecfb";
}
.cf-tips:before {
  content: "\ecfc";
}
.cf-tit:before {
  content: "\ecfd";
}
.cf-tix:before {
  content: "\ecfe";
}
.cf-tka:before {
  content: "\ecff";
}
.cf-tkn:before {
  content: "\ed00";
}
.cf-tkr:before {
  content: "\ed01";
}
.cf-tks:before {
  content: "\ed02";
}
.cf-tky:before {
  content: "\ed03";
}
.cf-tmt:before {
  content: "\ed04";
}
.cf-tnb:before {
  content: "\ed05";
}
.cf-tnc:before {
  content: "\ed06";
}
.cf-tns:before {
  content: "\ed07";
}
.cf-tnt:before {
  content: "\ed08";
}
.cf-tok:before {
  content: "\ed09";
}
.cf-tokc:before {
  content: "\ed0a";
}
.cf-tomo:before {
  content: "\ed0b";
}
.cf-tpay:before {
  content: "\ed0c";
}
.cf-trac:before {
  content: "\ed0d";
}
.cf-trak:before {
  content: "\ed0e";
}
.cf-trb:before {
  content: "\ed0f";
}
.cf-trc:before {
  content: "\ed10";
}
.cf-trct:before {
  content: "\ed11";
}
.cf-trig:before {
  content: "\ed12";
}
.cf-trst:before {
  content: "\ed13";
}
.cf-trtl:before {
  content: "\ed14";
}
.cf-true:before {
  content: "\ed15";
}
.cf-trump:before {
  content: "\ed16";
}
.cf-trust:before {
  content: "\ed17";
}
.cf-trx:before {
  content: "\ed18";
}
.cf-tryb:before {
  content: "\ed19";
}
.cf-tsl:before {
  content: "\ed1a";
}
.cf-ttc:before {
  content: "\ed1b";
}
.cf-ttt:before {
  content: "\ed1c";
}
.cf-tube:before {
  content: "\ed1d";
}
.cf-turbo:before {
  content: "\ed1e";
}
.cf-tusd:before {
  content: "\ed1f";
}
.cf-tx:before {
  content: "\ed20";
}
.cf-tzc:before {
  content: "\ed21";
}
.cf-ubq:before {
  content: "\ed22";
}
.cf-ubt:before {
  content: "\ed23";
}
.cf-ubtc:before {
  content: "\ed24";
}
.cf-ucash:before {
  content: "\ed25";
}
.cf-ufo:before {
  content: "\ed26";
}
.cf-ufr:before {
  content: "\ed27";
}
.cf-ugc:before {
  content: "\ed28";
}
.cf-uip:before {
  content: "\ed29";
}
.cf-uis:before {
  content: "\ed2a";
}
.cf-ukg:before {
  content: "\ed2b";
}
.cf-ult:before {
  content: "\ed2c";
}
.cf-uma:before {
  content: "\ed2d";
}
.cf-unb:before {
  content: "\ed2e";
}
.cf-uni:before {
  content: "\ed2f";
}
.cf-unify:before {
  content: "\ed30";
}
.cf-unit:before {
  content: "\ed31";
}
.cf-unity:before {
  content: "\ed32";
}
.cf-uno:before {
  content: "\ed33";
}
.cf-uos:before {
  content: "\ed34";
}
.cf-up:before {
  content: "\ed35";
}
.cf-upp:before {
  content: "\ed36";
}
.cf-uqc:before {
  content: "\ed37";
}
.cf-usdc:before {
  content: "\ed38";
}
.cf-usds:before {
  content: "\ed39";
}
.cf-usdt:before {
  content: "\ed3a";
}
.cf-usnbt:before {
  content: "\ed3b";
}
.cf-utc:before {
  content: "\ed3c";
}
.cf-utk:before {
  content: "\ed3d";
}
.cf-utnp:before {
  content: "\ed3e";
}
.cf-uuu:before {
  content: "\ed3f";
}
.cf-v:before {
  content: "\ed40";
}
.cf-vee:before {
  content: "\ed41";
}
.cf-veri:before {
  content: "\ed42";
}
.cf-vest:before {
  content: "\ed43";
}
.cf-vet:before {
  content: "\ed44";
}
.cf-via:before {
  content: "\ed45";
}
.cf-vib:before {
  content: "\ed46";
}
.cf-vibe:before {
  content: "\ed47";
}
.cf-vidt:before {
  content: "\ed48";
}
.cf-vikky:before {
  content: "\ed49";
}
.cf-vin:before {
  content: "\ed4a";
}
.cf-vit:before {
  content: "\ed4b";
}
.cf-vite:before {
  content: "\ed4c";
}
.cf-viu:before {
  content: "\ed4d";
}
.cf-vivo:before {
  content: "\ed4e";
}
.cf-vme:before {
  content: "\ed4f";
}
.cf-voise:before {
  content: "\ed50";
}
.cf-vrc:before {
  content: "\ed51";
}
.cf-vrm:before {
  content: "\ed52";
}
.cf-vrs:before {
  content: "\ed53";
}
.cf-vsl:before {
  content: "\ed54";
}
.cf-vsx:before {
  content: "\ed55";
}
.cf-vsys:before {
  content: "\ed56";
}
.cf-vtc:before {
  content: "\ed57";
}
.cf-vtr:before {
  content: "\ed58";
}
.cf-vzt:before {
  content: "\ed59";
}
.cf-wabi:before {
  content: "\ed5a";
}
.cf-wan:before {
  content: "\ed5b";
}
.cf-wand:before {
  content: "\ed5c";
}
.cf-warp:before {
  content: "\ed5d";
}
.cf-waves:before {
  content: "\ed5e";
}
.cf-wax:before {
  content: "\ed5f";
}
.cf-wbtc:before {
  content: "\ed60";
}
.cf-wct:before {
  content: "\ed61";
}
.cf-wdc:before {
  content: "\ed62";
}
.cf-wgr:before {
  content: "\ed63";
}
.cf-whl:before {
  content: "\ed64";
}
.cf-wib:before {
  content: "\ed65";
}
.cf-wild:before {
  content: "\ed66";
}
.cf-wings:before {
  content: "\ed67";
}
.cf-wish:before {
  content: "\ed68";
}
.cf-wpr:before {
  content: "\ed69";
}
.cf-wrc:before {
  content: "\ed6a";
}
.cf-wrx:before {
  content: "\ed6b";
}
.cf-wtc:before {
  content: "\ed6c";
}
.cf-wxt:before {
  content: "\ed6d";
}
.cf-xas:before {
  content: "\ed6e";
}
.cf-xaur:before {
  content: "\ed6f";
}
.cf-xbl:before {
  content: "\ed70";
}
.cf-xbp:before {
  content: "\ed71";
}
.cf-xbts:before {
  content: "\ed72";
}
.cf-xby:before {
  content: "\ed73";
}
.cf-xchf:before {
  content: "\ed74";
}
.cf-xcp:before {
  content: "\ed75";
}
.cf-xcpo:before {
  content: "\ed76";
}
.cf-xct:before {
  content: "\ed77";
}
.cf-xcxt:before {
  content: "\ed78";
}
.cf-xdce:before {
  content: "\ed79";
}
.cf-xdn:before {
  content: "\ed7a";
}
.cf-xel:before {
  content: "\ed7b";
}
.cf-xem:before {
  content: "\ed7c";
}
.cf-xes:before {
  content: "\ed7d";
}
.cf-xgox:before {
  content: "\ed7e";
}
.cf-xhv:before {
  content: "\ed7f";
}
.cf-xin:before {
  content: "\ed80";
}
.cf-xios:before {
  content: "\ed81";
}
.cf-xjo:before {
  content: "\ed82";
}
.cf-xlm:before {
  content: "\ed83";
}
.cf-xlq:before {
  content: "\ed84";
}
.cf-xlr:before {
  content: "\ed85";
}
.cf-xmcc:before {
  content: "\ed86";
}
.cf-xmg:before {
  content: "\ed87";
}
.cf-xmo:before {
  content: "\ed88";
}
.cf-xmr:before {
  content: "\ed89";
}
.cf-xmx:before {
  content: "\ed8a";
}
.cf-xmy:before {
  content: "\ed8b";
}
.cf-xnk:before {
  content: "\ed8c";
}
.cf-xnn:before {
  content: "\ed8d";
}
.cf-xns:before {
  content: "\ed8e";
}
.cf-xp:before {
  content: "\ed8f";
}
.cf-xpa:before {
  content: "\ed90";
}
.cf-xpd:before {
  content: "\ed91";
}
.cf-xpm:before {
  content: "\ed92";
}
.cf-xptx:before {
  content: "\ed93";
}
.cf-xrh:before {
  content: "\ed94";
}
.cf-xrl:before {
  content: "\ed95";
}
.cf-xrp:before {
  content: "\ed96";
}
.cf-xsh:before {
  content: "\ed97";
}
.cf-xsn:before {
  content: "\ed98";
}
.cf-xspec:before {
  content: "\ed99";
}
.cf-xsr:before {
  content: "\ed9a";
}
.cf-xst:before {
  content: "\ed9b";
}
.cf-xstc:before {
  content: "\ed9c";
}
.cf-xtl:before {
  content: "\ed9d";
}
.cf-xto:before {
  content: "\ed9e";
}
.cf-xtz:before {
  content: "\ed9f";
}
.cf-xvg:before {
  content: "\eda0";
}
.cf-xyo:before {
  content: "\eda1";
}
.cf-xzc:before {
  content: "\eda2";
}
.cf-yee:before {
  content: "\eda3";
}
.cf-yfi:before {
  content: "\eda4";
}
.cf-yoc:before {
  content: "\eda5";
}
.cf-yoyo:before {
  content: "\eda6";
}
.cf-ytn:before {
  content: "\eda7";
}
.cf-zai:before {
  content: "\eda8";
}
.cf-zap:before {
  content: "\eda9";
}
.cf-zb:before {
  content: "\edaa";
}
.cf-zcl:before {
  content: "\edab";
}
.cf-zco:before {
  content: "\edac";
}
.cf-zec:before {
  content: "\edad";
}
.cf-zel:before {
  content: "\edae";
}
.cf-zen:before {
  content: "\edaf";
}
.cf-zeni:before {
  content: "\edb0";
}
.cf-zeph:before {
  content: "\edb1";
}
.cf-zer:before {
  content: "\edb2";
}
.cf-zet:before {
  content: "\edb3";
}
.cf-zil:before {
  content: "\edb4";
}
.cf-zipt:before {
  content: "\edb5";
}
.cf-zla:before {
  content: "\edb6";
}
.cf-zny:before {
  content: "\edb7";
}
.cf-zoi:before {
  content: "\edb8";
}
.cf-zpt:before {
  content: "\edb9";
}
.cf-zrx:before {
  content: "\edba";
}
.cf-zsc:before {
  content: "\edbb";
}
.cf-zzc:before {
  content: "\edbc";
}
